import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
import Home from '@/views/home';
import MiddlePage from '@/views/middle';
import Login from '@/views/login';
import Login2 from '@/views/login/index2';
import Rights from '@/views/rights';
import RightsCode from '@/views/rights/indexCode';
import RightsActive from '@/views/rights/active';
import RightsPerson from '@/views/rights/person';
import RightsPersonNew from '@/views/newViews/rights/person';
import RightsDistribute from '@/views/newViews/rights/distribute';
import RightsPersonAddNew from '@/views/newViews/rights/personAddNew';
import RightsPersonAdd from '@/views/rights/personAdd';
import RightsPersonList from '@/views/rights/personList';
import RightsDetail from '@/views/rights/detail';
import showPdf from '@/views/pdf';
import ProductShow from '@/views/rights/productShow';
import ProgressComponent from '@/views/progress';
import ProgressComponent2 from '@/views/progress/step2';
import ProgressComponent2Base from '@/views/progress/step2Base';
import ProgressComponent2GP96 from '@/views/progress/step2GP96';
import ProgressComponent3 from '@/views/progress/step3';
import ProgressComponent3Base from '@/views/progress/step3Base';
import ProgressComponent4 from '@/views/progress/step4';
import AppointmentDetail from '@/views/appointment/detail';
import AppointmentList from '@/views/appointment/list';
import Personal from '@/views/personal';
import PersonalNew from '@/views/personalNew';
import jump1 from '@/views/personalNew/jump1';
import jump2 from '@/views/personalNew/jump2';
import jump3 from '@/views/personalNew/jump3';
import jump4 from '@/views/personalNew/jump4';
import Content1 from '@/views/content/content1';
import Content2 from '@/views/content/content2';
import serverPdfBase from '@/views/content/serverPdfBase';
import serverPdfPlus from '@/views/content/serverPdfPlus';
import serverPdfDrug from '@/views/content/serverPdfDrug';
import serverPdfBasePlus from '@/views/content/serverPdfBasePlus';
import settleIndex from '@/views/settle/index';
import settlePdmIndex from '@/views/settle/pdmIndex';
import settleOnlyIndex from '@/views/settle/indexOnly';
import settleOnlyIndexYL from '@/views/settle/indexOnlyYL';

import settleInfo from '@/views/settleInfo';
import settleInfoSuccess from '@/views/settleInfo/success';
import longHuShanInfo from '@/views/longHuShan';
import longHuShanInfoSuccess from '@/views/longHuShan/success';
import huaDaInfo from '@/views/huaDa';
import huaDaInfoSuccess from '@/views/huaDa/success';

import paysuccess from '@/views/settle/paysuccess';
import alertUpdate from '@/views/settle/alertUpdate';
import meetingIndex from '@/views/meeting/index';
import meetingSuccess from '@/views/meeting/success';
import payWxAlert from '@/views/settle/payWxAlert';

import LoginNew from '@/views/loginNew';
import { isWehat, onLogin } from './util';

const routes = [
  // {
  //   path: '/',
  //   meta: {
  //     title: '个人中心',
  //   },
  //   redirect: '/personal'
  // },
  {
    path: '/middle',
    name: 'middle',
    component: MiddlePage
  },
  // {
  //   path: '/login',
  //   component: Login
  // },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: Login2
  },
  {
    path: '/loginNew',
    name: 'loginNew',
    meta: {
      title: '登录',
    },
    component: LoginNew
  },
  {
    path: '/rights',
    name: 'rights',
    meta: {
      title: '权益激活',
    },
    component: Rights
  },
  {
    path: '/rightsCode',
    name: 'rightsCode',
    meta: {
      title: '权益激活',
    },
    component: RightsCode
  },
  {
    path: '/rightsActive',
    name: 'rightsActive',
    meta: {
      title: '权益激活',
    },
    component: RightsActive
  },
  {
    path: '/rightsPerson',
    name: 'rightsPerson',
    meta: {
      title: '我的权益',
    },
    component: RightsPerson
  },
  {
    path: '/rightsPersonNew',
    name: 'rightsPersonNew',
    meta: {
      title: '我的权益',
    },
    component: RightsPersonNew
  },
  {
    path: '/rightsDistribute',
    name: 'rightsDistribute',
    meta: {
      title: '分配详情',
    },
    component: RightsDistribute
  },
  {
    path: '/rightsPersonAdd',
    name: 'rightsPersonAdd',
    meta: {
      title: '添加家人',
    },
    component: RightsPersonAdd
  },
  {
    path: '/rightsPersonAddNew',
    name: 'rightsPersonAddNew',
    meta: {
      title: '添加成员',
    },
    component: RightsPersonAddNew
  },
  {
    path: '/rightsPersonList',
    name: 'rightsPersonList',
    meta: {
      title: '我的卡包',
    },
    component: RightsPersonList
  },
  {
    path: '/rightsDetail',
    name: 'rightsDetail',
    meta: {
      title: '权益详情',
    },
    component: RightsDetail
  },
  {
    path: '/progress',
    name: 'progress',
    meta: {
      title: '权益预约',
    },
    component: ProgressComponent
  },
  {
    path: '/progressStep2',
    name: 'progressStep2',
    meta: {
      title: '权益预约',
    },
    component: ProgressComponent2
  },
  {
    path: '/progressStep2Base',
    name: 'progressStep2Base',
    meta: {
      title: '权益预约',
    },
    component: ProgressComponent2Base
  },
  {
    path: '/progressStep2GP96',
    name: 'progressStep2GP96',
    meta: {
      title: '权益预约',
    },
    component: ProgressComponent2GP96
  },
  {
    path: '/progressStep3',
    name: 'progressStep3',
    meta: {
      title: '权益预约',
    },
    component: ProgressComponent3
  },
  {
    path: '/progressStep3Base',
    name: 'progressStep3Base',
    meta: {
      title: '权益预约',
    },
    component: ProgressComponent3Base
  },
  {
    path: '/progressStep4',
    name: 'progressStep4',
    meta: {
      title: '预约完成',
    },
    component: ProgressComponent4
  },
  {
    path: '/appointmentDetail',
    name: 'appointmentDetail',
    meta: {
      title: '预约完成',
    },
    component: AppointmentDetail
  },
  {
    path: '/appointmentList',
    name: 'appointmentList',
    meta: {
      title: '我的预约',
    },
    component: AppointmentList
  },
  {
    path: '/personal',
    name: 'personal',
    meta: {
      title: '个人中心',
    },
    component: Personal
  },
  {
    path: '/personalNew',
    name: 'personalNew',
    meta: {
      title: '个人中心',
    },
    component: PersonalNew
  },
  {
    path: '/jump1',
    name: 'jump1',
    meta: {
      title: '潘多姆',
    },
    component: jump1
  },
  {
    path: '/jump2',
    name: 'jump2',
    meta: {
      title: '潘多姆',
    },
    component: jump2
  },
  {
    path: '/jump3',
    name: 'jump3',
    meta: {
      title: '潘多姆',
    },
    component: jump3
  },
  {
    path: '/jump4',
    name: 'jump4',
    meta: {
      title: '潘多姆',
    },
    component: jump4
  },
  {
    path: '/content1',
    name: 'content1',
    meta: {
      title: '特药项目介绍',
    },
    component: Content1
  },
  {
    path: '/content2',
    name: 'content2',
    meta: {
      title: '国寿国药全面战略合作',
    },
    component: Content2
  },
  {
    path: '/serverPdfBase',
    name: 'serverPdfBase',
    meta: {
      title: '服务指南',
    },
    component: serverPdfBase
  },
  {
    path: '/serverPdfDrug',
    name: 'serverPdfDrug',
    meta: {
      title: '疾病清单',
    },
    component: serverPdfDrug
  },
  {
    path: '/serverPdfBasePlus',
    name: 'serverPdfBasePlus',
    meta: {
      title: '服务指南',
    },
    component: serverPdfBasePlus
  },
  {
    path: '/serverPdfPlus',
    name: 'serverPdfPlus',
    meta: {
      title: '服务指南',
    },
    component: serverPdfPlus
  },
  {
    path: '/productShow',
    name: 'productShow',
    meta: {
      title: '特药权益介绍',
    },
    component: ProductShow
  },
  {
    path: '/showPdf',
    name: 'showPdf',
    meta: {
      title: '特药权益介绍',
    },
    component: showPdf
  },
  {
    path: '/payWxAlert',
    name: 'payWxAlert',
    meta: {
      title: '支付提示',
    },
    component: payWxAlert
  },

  {
    path: '/settleIndex',
    name: 'settleIndex',
    meta: {
      title: '个人结算业务支付',
    },
    component: settleIndex
  },
  {
    path: '/settlePdmIndex',
    name: 'settlePdmIndex',
    meta: {
      title: '潘多姆健康管理',
    },
    component: settlePdmIndex
  },
  {
    path: '/settleInfo',
    name: 'settleInfo',
    meta: {
      title: '信息填写'
    },
    component: settleInfo
  },
  {
    path: '/longHuShanInfo',
    name: 'longHuShanInfo',
    meta: {
      title: '至臻至纯祈福开运'
    },
    component: longHuShanInfo
  },
  {
    path: '/longHuShanInfoSuccess',
    name: 'longHuShanInfoSuccess',
    meta: {
      title: '支付结果'
    },
    component: longHuShanInfoSuccess
  },
  {
    path: '/huaDaInfo',
    name: 'huaDaInfo',
    meta: {
      title: '国家基因细胞焕活计划'
    },
    component: huaDaInfo
  },
  {
    path: '/huaDaInfoSuccess',
    name: 'huaDaInfoSuccess',
    meta: {
      title: '支付结果'
    },
    component: huaDaInfoSuccess
  },
  
  {
    path: '/settleInfoSuccess',
    name: 'settleInfoSuccess',
    meta: {
      title: '支付结果'
    },
    component: settleInfoSuccess
  },
  {
    path: '/settleOnlyIndex',
    name: 'settleOnlyIndex',
    meta: {
      title: '个人结算业务支付',
    },
    component: settleOnlyIndex
  },
  {
    path: '/settleOnlyIndexYL',
    name: 'settleOnlyIndexYL',
    meta: {
      title: '个人结算业务支付',
    },
    component: settleOnlyIndexYL
  },
  
  {
    path: '/paysuccess',
    name: 'paysuccess',
    meta: {
      title: '支付结果',
    },
    component: paysuccess
  },
  {
    path: '/alertUpdate',
    name: 'alertUpdate',
    meta: {
      title: '系统升级中',
    },
    component: alertUpdate
  },
  {
    path: '/meetingReport',
    name: 'meetingReport',
    meta: {
      title: '参会登记',
    },
    component: meetingIndex
  },
  {
    path: '/meetingSuccess',
    name: 'meetingSuccess',
    meta: {
      title: '参会登记成功',
    },
    component: meetingSuccess
  },
  
  {
    path: '*',
    redirect: '/personal'
  },
  // {
  //   name: 'user',
  //   component: () => import('../views/user'),
  //   meta: {
  //     title: '会员中心'
  //   }
  // },
  // {
  //   name: 'cart',
  //   component: () => import('../views/cart'),
  //   meta: {
  //     title: '购物车'
  //   }
  // },
  // {
  //   name: 'goods',
  //   component: () => import('../views/goods'),
  //   meta: {
  //     title: '商品详情'
  //   }
  // }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
  mode: "history",
  routes
});

router.beforeEach(async (to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }

  // if (['/settleInfo', '/settleInfoSuccess'].includes(to.path)) {
  //   next();
  //   return;
  // }

  // const query = this.$route.query;
  const query = to.query;
  let publicType = localStorage.getItem('publicType');
  
  publicType = query.publicType || publicType || '3'; // 1 - 现有逻辑， 2 - 带有家庭版逻辑
  localStorage.setItem('publicType', publicType);
  if(to.path == '/settleIndex' || to.path == '/settlePdmIndex' || to.path == '/settleInfo' || to.path == '/longHuShanInfo' || to.path == '/huaDaInfo' || to.path == '/settleOnlyIndex' || to.path == '/settleOnlyIndexYL'){
    if(query.payType != null){
        localStorage.removeItem('code');
    }
    if(query.orgId != null){
      localStorage.setItem('orgId', query.orgId);
    }
  }
  let code = query.code || localStorage.getItem('code');
  let wechat = isWehat();
  let token = localStorage.getItem('token');
  let fPath = to.fullPath;
  fPath = fPath.slice(1);
  
  console.log("code===" + query.code);
  if (wechat) {
    console.log();
    if (!code) {
      //let httpUrl = 'http://www-test.healthmore.net';
      //let httpUrl = 'http://127.0.0.1:8080';
      let httpUrl = 'https://yisheng.healthmore.net';
      let appId = 'wxb0ea01d4fd25cc75'; // 1 - 生产环境
      //let appId = 'wxa8795af1a3a9ca7b'; //测试
      if (publicType == 1) {
        appId = 'wxd11a986432bc5343'; // 2 生产
        //appId = 'wxa8795af1a3a9ca7b'; //测试
      }

      // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0a96e693da6da47b&redirect_uri=http://127.0.0.1:8081/?url=${encodeURI(fPath)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${httpUrl}?url=${encodeURI(fPath)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
    } else {
      localStorage.setItem('code', code);
      
      let fullPath = query.url || to.fullPath;
      console.log('fullPath', to.path)
      // 权益页，不拦截
      if (to.path == '/rights' || to.path == '/content1' || to.path == '/content2' || to.path == '/productShow' || to.path == '/settleIndex' || to.path == '/settlePdmIndex' || to.path == '/settleInfo' || to.path == '/settleInfoSuccess' || to.path == '/longHuShanInfo' || to.path == '/longHuShanInfoSuccess' || to.path == '/huaDaInfo' || to.path == '/huaDaInfoSuccess' || to.path == '/settleOnlyIndex' || to.path == '/settleOnlyIndexYL' || to.path == '/meetingReport' || to.path == '/meetingSuccess' ||to.path == '/rightsCode' || to.path == '/alertUpdate' || to.path == '/paysuccess' ) {
        next();
        return;
      }
      
      
      let url = fullPath;
      if (to.query.code) {
        url = fullPath.replace('/personal?url=', '');
      }

      console.log('to', url)
      if (url.search(/rights\?/) > -1 || url.search(/content1/) > -1 || url.search(/content2/) > -1 || url.search(/productShow\?/) > -1 || url.search(/settleIndex\?/) > -1 || url.search(/settlePdmIndex\?/) > -1 || url.search(/settleInfo\?/) > -1 || url.search(/settleInfoSuccess\?/) > -1 || url.search(/longHuShanInfo\?/) > -1 || url.search(/longHuShanInfoSuccess\?/) > -1 || url.search(/huaDaInfo\?/) > -1 || url.search(/huaDaInfoSuccess\?/) > -1  || url.search(/settleOnlyIndex\?/) > -1 || url.search(/settleOnlyIndexYL\?/) > -1  || url.search(/meetingReport\?/) > -1 || url.search(/meetingSuccess/) > -1 || url.search(/rightsCode\?/) > -1 || url.search(/alertUpdate\?/) > -1 || url.search(/paysuccess\?/) > -1 ){
        url = url.replace('&payType=0', '');
        console.log('进入', url)
        next({
          path: url
        });
        return;
      }
      
      
      if (!token && (to.name != 'login'&& to.name != 'loginNew')) {
        let url = '';
        console.log('code', code);
        const res = await onLogin(code, fullPath);
        if (res.code == 200) {
          localStorage.setItem('token', res.data.token);
          const loginUrl = localStorage.getItem('loginUrl');
          if (loginUrl == '/loginNew') {
            url = fullPath.replace('/personalNew?url=', '');
          } else {
            url = fullPath.replace('/personal?url=', '');
          }
          
        } else if (res.code == 1004) {
          // 1004 需要注册
          url = '/login';
          if (publicType == 1) {
            url = '/loginNew';
          }
        }
        console.log(444)
        next({
          path: url
        })
      }
      next();
    }
  } else {

    let fullPath1 = to.fullPath;
    console.log('fullpATH1', fullPath1)
    let url1 = '';
    if(fullPath1.search(/\/settleIndex\?/) > -1 || fullPath1.search(/\/settlePdmIndex\?/) > -1 || fullPath1.search(/settleInfo\?/) > -1 || fullPath1.search(/settleInfoSuccess\?/) > -1 || fullPath1.search(/longHuShanInfo\?/) > -1 || fullPath1.search(/longHuShanInfoSuccess\?/) > -1 || fullPath1.search(/huaDaInfo\?/) > -1 || fullPath1.search(/huaDaInfoSuccess\?/) > -1 || fullPath1.search(/\/settleOnlyIndex\?/) > -1 || fullPath1.search(/\/settleOnlyIndexYL\?/) > -1){
      url1 = '/payWxAlert';
      next({
        path: url1
      });
      return ;
    }

    if (!token && (to.name != 'login' && to.name != 'loginNew')) {
        let fullPath = to.fullPath;
        let url = '';
        localStorage.setItem('redirectUrl', fullPath);
        console.log('fullpATH', fullPath)
        if (fullPath.search(/\/rights\?/) > -1 || fullPath.search(/\/content1/) > -1 || fullPath.search(/\/content2/) > -1 || fullPath.search(/\/productShow\?/) > -1 || fullPath.search(/\/meetingReport\?/) > -1 || fullPath.search(/\/meetingSuccess/) > -1 || fullPath.search(/\/rightsCode\?/) > -1|| fullPath.search(/\/alertUpdate\?/) > -1|| fullPath.search(/\/paysuccess\?/) > -1 || fullPath.search(/\/payWxAlert/) > -1){
            url = fullPath;
            console.log('4')
            next();
            return;
        }else {
            // 1004 需要注册
            // url = '/login';
            url = '/login';
            if (publicType == 1) {
              url = '/loginNew';
            }
            console.log('5')
        }
        next({
          path: url
        });
    }
    next();
  }
});
// router.beforeEach((to, from, next) => {
//   const title = to.meta && to.meta.title;
//   if (title) {
//     document.title = title;
//   }


  
//   next();
// });

export {
  router
};