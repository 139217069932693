<template>
  <div class="info">
    <div class="head-wrap content-padding">
      <img src="@/assets/huada/head-img.png" class="head-img" />
   
    </div>
    <div class="content-padding">
      <div class="section-content" >
        <img class="sec-title" src="@/assets/huada/block-1.png" />
        <div class="manage-info manage-info-title content" >

          <div class="manage-info_content" style="padding-top:0;">
            <div class="input-wrap">
              <div class="key">
                业务员姓名
              </div>
              <div class="val">
                <van-field v-model="form.payPersonName" placeholder="请输入姓名" />
              </div>
            </div>
            <div class="input-wrap">
              <div class="key">
                业务员工号
              </div>
              <div class="val">
                <van-field v-model="form.payPersonNo" placeholder="请输入14位工号" />
              </div>
            </div>
            <div class="input-wrap" style="margin-bottom: 0;">
              <div class="key">
                业务员手机号
              </div>
              <div class="val">
                <van-field v-model="form.payPersonPhone" placeholder="请输入手机号" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
       
        <div class="content" style="margin-top:-40px">
          <div class="manage-info manage-info-repeat" v-for="(item, index) in form.itemList" :key="index">
            <!--   <div class="manage-info_header person-title" v-if="index == 0">
              <h4>客户个人信息</h4>
              <p>以人为本破除久医不治枷锁</p>
           
            </div> -->
            <div class="manage-info_content">
              <section class="section-content">
                <img class="sec-title" src="@/assets/huada/block-2.png" />
                <div class="input-wrap">
                <div class="key">
                  姓名
                </div>
                <div class="val">
                  <van-field v-model="item.personName" placeholder="请输入姓名" />
                </div>
              </div>
              <div class="input-wrap">
                <div class="key">身份证号
                </div>
                <div class="val">
                  <van-field v-model="item.personIdCard" placeholder="请输入身份证号" />
                </div>
              </div>
              <div class="input-wrap">
                <div class="key">
                  手机号
                </div>
                <div class="val">
                  <van-field v-model="item.personPhone" placeholder="请输入手机号" />
                </div>
              </div>

              <div class="input-wrap">
                <div class="key">
                  开始日期
                </div>
                <div class="val">
                  <van-cell-group>
                    <van-cell :title="form.itemList[index]['checkStartTime']" @click="onHandleChoiceDate(index)"
                      :class="[{ link: !isLink }]">
                      <template #right-icon>
                        <img class="right-icon" src="@/assets/huada/icon-clock.png" />
                      </template>
                    </van-cell>
                    <van-calendar v-model="show" :min-date="minDate" @confirm="onConfirm" color="#07A8FF" />
                  </van-cell-group>
                </div>
              </div>
              </section>
           



            <section class="section-content">
              <img class="sec-title" src="@/assets/huada/block-3.png" />
              <div class="project-list">
                <ul v-for="(listItem, listIndex) in list" :key="listIndex">
                  <li v-for="projectItem in listItem" :key="projectItem.id" :class="[
                    { active: item.productList.find((item)=>item.productId==projectItem.id) },
                  ]" @click="onHandleChoice(index, projectItem)">
                    <p class="title">{{ projectItem.name }}</p>
                    <p class="title">{{ projectItem.tag }}</p>
                    <p class="middle"></p>
                    <p>￥{{ projectItem.label }}</p>
                    <div class="item-success" v-if="item.productList.find(item=>item.productId==projectItem.id)">
                      <img src="@/assets/huada/select.png" />
                    </div>
                  </li>
                </ul>
              </div>
            </section>
              <section class="money section-content" v-if="item.productList.length">
                <img class="sec-title" src="@/assets/huada/block-4.png" />
              <!--   <div class="money-title label" style="margin-top:16px;font-weight: 500;">项目费用</div> -->
                <div v-for="(expendItem, expendIndex) in expendList" :key="expendIndex" class="money-info">
                  <div v-if="item.productList.find((item=>item.productId==expendItem.id))" class="money-info_content">
                    <span>{{ expendItem.name }}{{ expendItem.tag }}</span>
                    <span style="display: flex; align-items: center;">￥{{ expendItem.label }} &nbsp;&nbsp;<van-stepper  @change="stepperChange(index)" v-model="item.productList.find((item=>item.productId==expendItem.id)).productCount" integer /></span>
                  </div>
                </div>
                <div class="money-footer">
                  <span>总计</span>
                  <span>￥{{ item.sum }}</span>
                </div>
              </section>
              <div class="remove-info">
                <div class="remove-user flex flex-column-center" @click="onHandleRemove(index)">
                </div>
              </div>
            </div>
          </div>
          <!-- 添加按钮 -->
          <div class="add-info">
            <div class="add-user flex flex-column-center" @click="onHandleAdd">
              <!-- <img src="@/assets/pdmInfo/plus.png" />
      <span>添加用户</span> -->
            </div>
          </div>

        </div>
      </div>



    </div>


    <!-- 底部 -->
    <div class="info-footer flex flex-between flex-align-center">
      <dl>
        <dt>总计</dt>
        <dd>￥{{ sum }}</dd>
      </dl>
      <div class="info-footer_right">
        <div class="save btn" @click="onHandleSave"></div>
        <div class="pay-now btn" @click="onHandleSubmit"></div>
      </div>
    </div>
    <van-overlay :show="loading">
      <van-loading size="40" vertical class="loadingDiv">支付中...</van-loading>
    </van-overlay>
  </div>
</template>
<script>
import { Toast } from "vant";
import { list, expendList, item } from "./config";
import { onSettleInfoSubmitHuaDa } from "@/api";
import { weixinInit } from "@/utils/wechatUtil.js";
export default {
  data() {
    return {
      title: "请选择日期",
      isLink: true,
      show: false,
      loading: false,
      // wxCode: code,
      urlString: "",
      list,
      expendList,
      form: {
        payPersonName: "", // 业务员姓名
        payPersonNo: "", // 业务员工号
        payPersonPhone: "", // 业务员手机号
        itemList: [JSON.parse(JSON.stringify(item))],
      },
      sum: 0, // 总计
      orgId: "",
      currentItem: null,
      currentIndex: 0,
    };
  },
  created() {
    var currentDate = new Date();
    // 将日期增加一天
    currentDate.setDate(currentDate.getDate() + 1);
    this.minDate = currentDate;

    this.orgId = localStorage.getItem("orgId") || "";
    this.urlString = window.location.href.split("#")[0];

    const form = localStorage.getItem("pdm_cache");

    if (form) {
      this.form = JSON.parse(form);
      this.onGetTotalSum();
    }
    this.wxConfigInit();
  },
  methods: {
    onHandleChoiceDate(index) {
      this.currentIndex = index;
      this.currentItem = this.form.itemList[index];
      this.show = true;
    },
    onPad(num) {
      return num < 10 ? "0" + num : num;
    },
    formatDate(date) {
      return `${date.getFullYear()}-${this.onPad(
        date.getMonth() + 1
      )}-${this.onPad(date.getDate())}`;
    },
    onConfirm(date) {
      this.show = false;
      // console.log("this.formatDate(date)", this.formatDate(date));
      // this.date = this.formatDate(date);
      this.form.itemList[this.currentIndex]["checkStartTime"] =
        this.formatDate(date);
      this.isLink = false;
      this.title = this.form.itemList[this.currentIndex]["checkStartTime"];
    },
    async wxConfigInit() {
      let weixinShare = {
        publicType: 1,
        url: this.urlString,
      };
      await weixinInit(weixinShare);
    },
    //唤起微信支付
    async weixinPay(weixinPayParam) {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        this.onBridgeReady(weixinPayParam);
      }
    },
    async onBridgeReady(weixinPayParam) {
      let payNoDiy = weixinPayParam.payNo;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: weixinPayParam.appId,
          timeStamp: weixinPayParam.timeStamp,
          nonceStr: weixinPayParam.nonceStr,
          package: weixinPayParam.packages,
          signType: weixinPayParam.signType,
          paySign: weixinPayParam.paySign,
        },
        function (res) {
          localStorage.removeItem("pdm_cache");
          window.location.href =
            "https://yisheng.healthmore.net/settleInfoSuccess?payNo=" +
            payNoDiy;
          //this.$router.push(`/paysuccess?payNo=${payNoDiy}`);
        }
      );
    },
    // 添加用户
    onHandleAdd() {
      this.form.itemList.push(JSON.parse(JSON.stringify(item)));
    },
    //删除用户
    onHandleRemove(index) {
      if (this.form.itemList.length === 1) {
        Toast("至少保留一个用户信息");
        return;
      }
      // 删除指定索引的用户信息
      this.form.itemList.splice(index, 1);
      // 更新总金额
      this.onGetTotalSum();
    },
    // 选择
    onHandleChoice(index, item) {
      
      console.log("我是第" + index + "个");
      console.log("item", index, item);
      console.log(this.form.itemList[index]);
 
      const selectIndex = this.form.itemList[index]["productList"].findIndex(item2=>item2.productId==item.id);
      console.log("selectIndex", selectIndex);
   
      if (selectIndex != -1) {
        this.form.itemList[index]["productList"].splice(selectIndex, 1);
        this.form.itemList[index]["sum"] -= item.label;
      } else {
        this.form.itemList[index]["productList"].push({productId:item.id,productCount:1});
        this.form.itemList[index]["sum"] += item.label;
      }
      console.log("sele", this.form.itemList[index]["productList"]);
      this.onGetTotalSum();
    },
    stepperChange(listIdx){

      let sum=0;
      this.form.itemList[listIdx]["productList"].forEach(item=>{
        const selectedItem = this.expendList.filter((expendedItem) => expendedItem.id == item.productId);
        sum+= selectedItem[0].label*item.productCount

      })
      this.form.itemList[listIdx]["sum"]=sum
      this.onGetTotalSum();
    },
    // 计算总金额
    onGetTotalSum() {
      let sum = 0;
      this.form.itemList.forEach((item) => {
        item.productList.forEach((innerItem) => {
          const selectedItem = this.expendList.filter(
            (expendedItem) => expendedItem.id == innerItem.productId
          );
          console.log("selectedItem", selectedItem);
          sum += selectedItem[0].label*innerItem.productCount;
        });
      });
      this.sum = sum;
    },
    // 暂存
    onHandleSave() {
      localStorage.setItem("pdm_cache", JSON.stringify(this.form));
      Toast("暂存成功");
    },
    // 去支付
    onHandleSubmit() {
      console.log("this.form", JSON.parse(JSON.stringify(this.form)));

      if (!this.form.payPersonName) {
        Toast("请填写业务员姓名");
        return false;
      }
      if (!this.form.payPersonNo) {
        Toast("请填写业务员工号");
        return false;
      }
      if (!this.form.payPersonPhone) {
        Toast("请先输入业务员手机号");
        return;
      }
      if (this.form.payPersonPhone.length != 11) {
        Toast("请输入正确的业务员手机号");
        return;
      }
      const list = this.form.itemList;
      for (let i = 0; i < list.length; i++) {
        if (!list[i].personName) {
          Toast("请填写客户姓名");
          return false;
        }
        if (!list[i].personIdCard) {
          Toast("请填写客户客户身份证号");
          return false;
        }
        if (!list[i].personPhone) {
          Toast("请填写客户手机号");
          return false;
        }
        if (list[i].personPhone.length != 11) {
          Toast("请输入正确的客户手机号");
          return;
        }
        if (!list[i].checkStartTime) {
          Toast("请选择检查日期");
          return;
        }
        if (!list[i].productList.length) {
          Toast("请至少选择一个项目");
          return false;
        }
      }
      // 都校验通过，暂存
      // this.onHandleSave();
      this.onHandleFinalSumit();
    },
    // 提交接口
    async onHandleFinalSumit() {
      this.loading = true;
      let code = localStorage.getItem("code") || "";
      let values = {
        wxCode: code,
        saleOrgId: this.orgId,
        ...this.form,
      };
      try {
        const res = await onSettleInfoSubmitHuaDa(values);
        console.log("res", res);
        if (res.code == 200) {
          this.weixinPay(res.data);
        } else {
          this.$toast(res.msg);
          this.loading = false;
        }
      } catch (err) {
        console.log("err", err);
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.section-content{
  background-color:#fff;
  border-radius: 16px;
  padding:0 15px 15px;
  margin-bottom: 40px;
  .sec-title{
    position: relative;
    top:-20px;
    left:-15px;
    height:45px;
  }
}
* {
  box-sizing: border-box;
}
.head-img{
  width:100%;
  
 max-width: 402px;
 margin-top:-30px;
}

img {
  display: block;
  max-width: 100%;
}

.head-wrap {
  padding: 0px 0 50px;
  overflow: hidden;

  .title {
    width: 298px;
    margin-bottom: 30px;
  }

  .des {
    width: 165px;

  }
}

.content-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.section {
  border-radius: 12px;
  margin-bottom: 16px;


  .sec-title {

  }

  .content {
    padding-top:20px;
  
    border-radius: 12px;
  }

}

.input-wrap {
  margin-bottom: 16px;

  .key {
    color: #363636;
    font-size: 14px;
    margin-bottom: 12px;
  }

  .val {
    /deep/ .van-cell {
      border: none;
      background-color: #EAF6FE;

      /deep/input::placeholder {
        color: #B8D7EB !important;
      }
    }
  }
}

.label {
  color: #363636 !important;
  font-size: 14px !important;
  margin-bottom: 12px !important;
}

@font-face {
  font-family: "SongtiSC";
  src: url("../../assets/pdmInfo/fonts/song.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  /* 可选，用于字体加载期间的替代字体 */
}

@font-face {
  font-family: "songCN";
  src: url("../../assets/pdmInfo/fonts/songCN.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  /* 可选，用于字体加载期间的替代字体 */
}

img {
  margin: 0;
  display: block;
}

/deep/ .van-cell-group {}

/deep/ .right-icon {
  width: 18px;
  height: 18px;
}

/deep/ .van-cell {}

/deep/ .van-field__control::placeholder,
/deep/ .van-cell__title,
/deep/ .van-cell__right-icon {}

.loadingDiv {
  position: absolute;
  left: 42%;
  top: 40%;
}

.info {
  position: relative;
  padding-bottom: 100px;
  background-color:#BBDEFA;

  // background: url(../../assets/pdmInfo/test.png);
  // background-position: top left;
  // background-size: 100% auto;
  // background-repeat: repeat-y;
  // padding: 0 0 100px;
  &-banner {
    width: 100%;
  }

  .manage-info {
    // margin-bottom: 16px;
    overflow: hidden;
    margin-bottom: 20px;

    &-title {

      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    &-repeat {

      background-repeat: repeat-y;
      background-size: 100% 100%;
    }

    &_header {
      padding: 20px 16px 5px;

      // text-align: center;
      &.worker-title {
        h4 {
          color: #000;
          font-size: 20px;
          font-weight: 900;
          position: relative;
          padding-bottom: 10px;
          margin-bottom: 10px;

          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 1px;
            background: #000;
            width: 170px;
          }
        }

        p {
          font-size: 14px;
          color: #000;
        }

        img {
          width: 193px;
        }
      }

      &.person-title {
        text-align: right;
        background-repeat: no-repeat;
        background-position: left top;
        background-size: 305px 95px;

        h4 {
          font-size: 20px;
          font-weight: 900;
          color: #000;
          position: relative;
          padding-bottom: 10px;
          margin-bottom: 10px;

          &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            height: 1px;
            background: #000;
            width: 170px;
          }
        }

        p {
          font-size: 14px;
          color: #000;
        }

        img {
          width: 173px;
        }
      }

      // h4 {
      //   font-family: AR XinYiGBJF UL;
      //   font-size: 18px;
      //   color: rgb(0, 150, 104);
      // }
      // p {
      //   font-size: 13px;
      //   color: rgb(0, 150, 104);
      // }
    }

    &_content {
      // background: #fff;
      // border-radius: 16px;
      padding-top:20px;

      .link /deep/ .van-cell__title {
        color: #333;
      }

      &>p {
        color: #000;
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: 900;
      }

      /deep/ .van-cell {
        // background: rgb(246, 251, 249);
        // border-radius: 12px;

      }

      /deep/ .van-cell::after {
        border-bottom: none;
      }

      .project-list {

        // border-radius: 12px;
        // padding: 10px;
        // background: linear-gradient(
        //   181.71deg,
        //   rgba(149, 244, 215, 1) -381.86%,
        //   rgba(255, 255, 255, 1) 125.61%
        // );
        margin: -3px;

        ul {
          display: flex;
          margin-bottom: 6px;
          flex-wrap: wrap;

          flex-wrap: wrap;

          li {
            width: auto;
            position: relative;
            border: 1px solid #B8D7EB;
            border-radius: 8px;
            padding: 6px 9px 4px;
            margin: 3px;



            &.active {
              border: 1px solid #0374C3;
            }

            &.flex-2 {
              padding-left: 3px;
              padding-right: 3px;
            }

            &:last-child {
              margin-right: 0;
            }

            .item-success {
              position: absolute;
              right: -1px;
              top: -1px;
              width: 16px;
              height: 16px;

              img {
                position: absolute;
                width: 100%;
                height: 100%;
              }
            }

            p {
              font-size: 11px;
              color: rgb(255, 60, 28);
              margin: 8px 0;
              text-align: center;
              font-weight: 900;

              &.title {
                font-size: 11px;
                font-weight: 500;
                color: #363636;
                margin: 2px 0;
              }

              &.middle {
                margin: 10px 8px;
                height: 1px;
                background: url("../../assets/huada/line.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
                // background: linear-gradient(
                //   90deg,
                //   rgba(255, 255, 255, 1) 0%,
                //   rgba(212, 251, 238, 1) 50%,
                //   rgba(255, 255, 255, 1) 100%
                // );
              }
            }
          }
        }
      }

      .money {
        &-title {
          color: #363636;
  font-size: 14px;
  margin-bottom: 12px;
        }

        &-info {
          &_content {
            display: flex;
            justify-content: space-between;
            margin: 14px 0;

            span {
              font-size: 13px;
              color: rgb(30, 30, 30);
              font-weight: 400;

              &:last-child {
                color: rgb(255, 60, 28);
              }
            }
          }
        }

        &-footer {
          display: flex;
          justify-content: space-between;
          padding: 16px 0;
          border-top: 1px solid #EAF6FE;

          span {
            font-size: 16px;
            font-weight: 900;
            color: rgba(30, 30, 30);

            &:last-child {
              color: rgb(255, 60, 28);
            }
          }
        }
      }
    }
  }

  .add-info {
    padding: 0 15px 20px;

    background-color: #F7FCFF;
    margin-top:-21px;
    border-radius: 0 0 16px 16px;
  }

  .remove-info {


    margin-top: -50px;
    background-color:#fff;
    padding:15px;
  }

  .add-user {
    background: url("../../assets/huada/btn-add.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 50px;
  }

  .remove-user {
    background: url("../../assets/huada/btn-remove.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 45px;
  }

  &-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    border-radius: 16px 16px 0 0;
    padding: 20px 12px 30px;

    dl {
      dt {
        font-size: 12px;
        color: #000;
      }

      dd {
        font-size: 18px;
        color: rgb(255, 60, 28);
        font-weight: 900;
      }
    }

    &_right {
      font-size: 16px;
      font-weight: 900;
      display: flex;

      .btn {
        width: 111px;
        height: 42px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      .save {

        background-image: url("../../assets/huada/btn-l.png");
        padding: 7px 40px;
      }

      .pay-now {
        background-image: url("../../assets/huada/btn-r.png");

        padding: 7px 20px;

      }
    }
  }
}

.info {
  background-image: url("../../assets/huada/body-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
