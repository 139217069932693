// const list1 = [{
//     id: 1,
//     className: 'flex-1',
//     name: '体检',
//     label: 2398
// }, {
//     id: 2,
//     className: 'flex-1',
//     name: '降血脂',
//     label: 3784
// }, {
//     id: 3,
//     className: 'flex-1',
//     name: '降血糖',
//     label: 98341
// }];
const list3 = [{
    id: 1,
    className: 'flex-1',
    name: '外泌体雾化',
    label: 3600,
    tag: ''
}, {
    id: 2,
    className: 'flex-1',
    name: '全基因测序',
    label: 5999,
    tag: ''
}, {
    id: 3,
    className: 'flex-1',
    name: '外泌体回输',
    label: 7999,
    tag: ''
}]
// const list2 = [{
//     id: 7,
//     className: 'flex-1',
//     name: '降血压',
//     label: 3948
// }, {
//     id: 8,
//     className: 'flex-1',
//     name: '消化系统',
//     label: 98341
// }, {
//     id: 9,
//     className: 'flex-1',
//     name: '心脏彩超',
//     label: 3948
// }];
export const list = [list3];
export const expendList = [...list3];
export const item = JSON.parse(JSON.stringify({
    personName: "", // 客户姓名
    personIdCard: "", // 客户身份证号
    personPhone: "", // 客户手机号
    checkStartTime: "", // 开始时间
    productList: [], // 产品列表
    sum: 0, // 总计
  }));